const firebaseConfig = {
    apiKey: "AIzaSyDsh210Pe7bRNQkEDo0dgeBVrTfx_ND_Hc",
    authDomain: "jerins-parlour-jp.firebaseapp.com",
    projectId: "jerins-parlour-jp",
    storageBucket: "jerins-parlour-jp.appspot.com",
    messagingSenderId: "68392443953",
    appId: "1:68392443953:web:9d8430c3527bc8e3b1f510"
};

/* const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
}; */

export default firebaseConfig;